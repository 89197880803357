import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import FadeIn from "../components/FadeIn"
import Mar from "../images/mar_big.png"

const About = () => (
  <Layout>
    <SEO title="Over mij" />
    <div className="row">
      <div className="col-12">
        <FadeIn src={Mar} alt="Marjolein Knoppers" />
        <h1>Over mij</h1>
        <p>
          Marjolein Knoppers werkt als sociaal psychiatrisch verpleegkundige en
          heeft ruime ervaring in het begeleiden en behandelen van
          (jong-)volwassenen met uiteenlopende hulpvragen. Ze merkte in haar
          werk steeds vaker dat er naast de psychosociale behandeling en
          begeleiding, behoefte was aan praktische en concrete hulp. Aan iemand
          die thuis komt en begeleiding en coaching biedt om ordening aan te
          brengen in huis. Omdat er raakvlakken zijn tussen chaos in je hoofd en
          chaos in je huis, ontstond het idee om een combinatie te maken van
          twee beroepen: Sociaal Psychiatrisch Verpleegkundige en Professional
          Organizer.
        </p>
      </div>

      <div className="col-12">
        <h2>Sociaal Psychiatrisch Verpleegkundige</h2>
        <p>
          De Sociaal Psychiatrisch Verpleegkundige verleent hulp, begeleiding of
          behandeling aan mensen met psychische problemen of psychiatrische
          aandoeningen, die vaak ook te kampen hebben met allerlei andere
          moeilijkheden op uiteenlopende levensgebieden. De sociaal
          psychiatrisch verpleegkundige probeert het functioneren te verbeteren
          en klachten te verminderen. Ook probeert ze terugval te voorkomen.
        </p>

        <h2>Professional Organizer</h2>
        <p>
          Het beroep Professional Organizer is afkomstig uit Amerika. In 1997 is
          het beroep in Nederland geïntroduceerd en is er een beroepsvereniging
          opgericht, de NBPO. De verwachting is dat de behoefte aan Professional
          Organizers zal toenemen vanwege de alsmaar groeiende informatiestroom,
          het hoge leeftempo en de grote werkdruk. De Professional
          Organizer begeleidt en adviseert bij het creëren van orde en overzicht
          in tijd en ruimte. Een Professional Organizer helpt u keuzes te maken
          die u in staat stellen gestructureerder te denken en te werken,
          effectiever om te gaan met tijd, ruimte en middelen en meer grip op uw
          leven en energiebalans te krijgen.
        </p>
      </div>
    </div>
  </Layout>
)

export default About
